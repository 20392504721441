import React, { useEffect, useState } from 'react';
import santiHead from '../../../assets/images/santiHeadshot.jpg';
import './Home.css';

function Home() {
  const [darkMode, setDarkMode] = useState(false);
  useEffect(() => {
    document.body.classList.toggle('dark-mode', darkMode);
  }, [darkMode]);
  function toggleDarkMode() {
    setDarkMode(!darkMode);
  }
  return (
    <>
      <div className="main-container">
        <div className="main-left">
          <h1>Santiago Giraldo</h1>
          <h3>
            Student, Orientation Leader (NSE), Dean's Leader (School of
            Computing)
          </h3>
          <p>
            Hi! My name is Santiago Giraldo, and I am a Senior Computer Science
            major with a Mathematics and Spanish minor here at the University of
            Nebraska-Lincoln. I am originally from Medellin, Colombia, and moved
            to the small town of Cambridge, Nebraska at the age of ten. This
            move was a substantial part of my life and at times I was unsure
            about the moving process, learning a brand new language, and
            attending a new school. Thankfully, I was welcomed into the
            community and could teach myself English in what some would consider
            “incredibly fast”. Now, I am to the point where new people I meet
            cannot believe that I am not from the United States due to my lack
            of accent. However, I have been able to maintain my fluency in
            Spanish and continue to improve my English vocabulary more and more.
            I graduated from Cambridge High School with a class size of thirty
            and was super excited about continuing my education at Nebraska.
          </p>
          <button onClick={toggleDarkMode}>Toggle Dark Mode</button>
        </div>
        <div className="main-right">
          <img
            src={santiHead}
            alt="My Portrait"
            // width="300px"
            className="myHeadStyle"
          />
          {/* <img src="https://i.imgur.com/0Z0Z0Z0.jpg" alt="Image" /> */}
          <p className="main-caption">
            Santiago Giraldo | Photos taken for NSE
          </p>
        </div>
        <div className="main-bottom">
          <h2>Courses Taken & Scheduled to Take</h2>
          <p>
            Not sure what these mean?&nbsp;
            <a
              href="https://catalog.unl.edu/undergraduate/courses/csce/"
              target="_blank"
              rel="noreferrer"
            >
              Click here!
            </a>
          </p>
          <ul>
            <li>
              Fall 2020 - CSCE 155, CSCE 10, MATH 106, ENGL 277, ECON 200, CASC
              191
            </li>
            <li>
              Spring 2021 - CSCE 156, CSCE 235, MATH 107, MATH 191, CASC 191
            </li>
            <li>Fall 2021 - CSCE 310, CSCE 251, GEOG 155, CASC 191</li>
            <li>
              Spring 2022 - CSCE 231, CSCE 310, MATH 314, SPAN 300A, UGEP 102,
              CASC 191
            </li>
            <li>
              Fall 2022 - CSCE 361, CSCE 428, MATH 380, SPAN 306, CASC 191
            </li>
            <li>
              Spring 2023 - CSCE 322, CSCE 464, GEOL 101, SPAN 621, CASC 191
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Home;

import React from 'react';
import '../home/Home.css';
import './About.css';
import MyButton from '../../tools/MyButton';

function About() {
  return (
    <>
      <div className="my-background">
        <div className="my-background-header">Santiago Giraldo</div>
      </div>
      <div className="my-section">
        <div className="my-header">Welcome to my Website!</div>
        <MyButton />
      </div>
      <div className="my-section">
        <div className="my-header">Welcome to my Website AGAIN!</div>
        <MyButton />
      </div>
    </>
  );
}

export default About;

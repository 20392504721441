import { Box, Button, Dialog, Hidden } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import AuthDetailsNav from './AuthDetailsNav';

const navbarItems = [
  {
    text: 'Home',
    to: '/',
  },
  {
    text: 'About',
    to: '/about',
  },
  {
    text: 'Contact',
    to: '/contact',
  },
  {
    text: 'Apps',
    to: '/apps',
  },
  {
    text: 'Resume',
    to: '/resume',
  },
];

const myStyles = {
  navLinks: {
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    padding: '0.5rem 1rem',
    height: '100%',
  },
  fa_bars: {
    color: '#fff',
  },
  '@media screen and (max-width: 960px)': {
    __expression__: 'screen and (max-width: 960px)',
    NavbarItems: {
      position: 'relative',
    },
    navMenu: {
      display: 'flex',
      flexDirection: 'column',
      width: '10%',
      position: 'absolute',
      top: '15px',
      marginLeft: '2rem',
      color: '#ffff',
      fontSize: '3rem',
      left: '0',
      opacity: '1',
      transition: 'all 0.5s ease',
    },
    navMenuActive: {
      background: '#242222',
      left: '0',
      opacity: '1',
      transition: 'all 0.5s ease',
      zIndex: '1',
    },
    fa_times: {
      color: '#fff',
      fontSize: '2.5rem',
      alignItems: 'right',
    },
    navItemMobile: {
      height: '80px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textDecoration: 'none',
    },
    navLinksMobileNew: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      fontSize: '18px',
      display: 'flex',
      alignItems: 'center',
      color: '#fff',
      textDecoration: 'none',
      height: '100%',
      width: '100%',
    },
  },
};

function Navbar() {
  const [darkMode, setDarkMode] = useState(false);
  useEffect(() => {
    document.body.classList.toggle('dark-mode', darkMode);
  }, [darkMode]);
  function toggleDarkMode() {
    setDarkMode(!darkMode);
  }

  const [open, setOpen] = useState(false);

  const onOpenHandler = () => setOpen(!open);
  const onCloseHandler = () => setOpen(false);

  const mappedItems = navbarItems.map(({ to, text }) => {
    return (
      <Link to={to} className="navbarItem">
        <Button
          className="navLinks"
          style={myStyles.navLinks}
          onClick={onCloseHandler}
        >
          {text}
        </Button>
      </Link>
    );
  });
  const mappedItemsMobile = navbarItems.map(({ to, text }) => {
    return (
      <Link
        to={to}
        className="navItemMobile"
        style={myStyles['@media screen and (max-width: 960px)'].navItemMobile}
      >
        <Button
          className="navLinksMobileNew"
          style={
            myStyles['@media screen and (max-width: 960px)'].navLinksMobileNew
          }
          onClick={onCloseHandler}
        >
          {text}
        </Button>
      </Link>
    );
  });

  return (
    <>
      <nav className="navbar">
        <Button className="navbarItem">
          <Link to="/" className="navbarLogo" onClick={onCloseHandler}>
            {/* SITE NAME */}
            <i className="fa-sharp fa-solid fa-house"></i>
          </Link>
        </Button>

        <div className="navbarContainer">
          <Hidden mdDown>
            <Box display="flex" gap={2}>
              {mappedItems}
            </Box>
          </Hidden>
          <button onClick={toggleDarkMode}>Color Mode</button>
          <Link to="/account" className="personIcon" onClick={onCloseHandler}>
            <AuthDetailsNav />
          </Link>
        </div>

        <Hidden mdUp>
          <div
            className="navMenu"
            style={myStyles['@media screen and (max-width: 960px)'].navMenu}
            onClick={onOpenHandler}
          >
            <i
              className={open ? 'fas fa-times' : 'fas fa-bars'}
              style={
                (myStyles['@media screen and (max-width: 960px)'].fa_times,
                myStyles.fa_bars)
              }
            />
          </div>
          <Dialog
            open={open}
            fullScreen
            fullWidth
            PaperProps={{
              sx: {
                boxShadow: 'none',
                backgroundColor: '#242222',
              },
            }}
            style={
              myStyles['@media screen and (max-width: 960px)'].navMenuActive
            }
          >
            <Box py={5} marginTop="auto" gap={2}>
              {mappedItemsMobile}
            </Box>
          </Dialog>
        </Hidden>
      </nav>
    </>
  );
}

export default Navbar;

import React from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import './Todo.css';

// const style = {
//   listItem: `flex justify-between bg-slate-200 p-4 my-2 capitalize`,
//   listItemComplete: `flex justify-between bg-slate-400 p-4 my-2 capitalize line-through`,
//   row: `flex`,
//   text: `ml-2 cursor-pointer`,
//   textComplete: `ml-2 cursor-pointer line-through`,
//   button: `cursor-pointer flex items-center`,
// };

const Todo = ({ todo, toggleComplete, deleteTodo }) => {
  return (
    <li
      // className={todo.completed ? style.listItemComplete : style.listItem}
      className={todo.completed ? 'listItemComplete' : 'listItem'}
    >
      <div
        // className={style.row}
        className="row"
      >
        <input
          onChange={() => toggleComplete(todo)}
          type="checkbox"
          checked={todo.completed ? 'checked' : ''}
        />
        <p
          onClick={() => toggleComplete(todo)}
          // className={todo.completed ? style.textComplete : style.text}
          className={todo.completed ? 'textComplete' : 'text'}
        >
          {todo.text}
        </p>
      </div>
      <button
        onClick={() => deleteTodo(todo.id)}
        // className={style.button}
        className="button"
      >
        {' '}
        {<FaRegTrashAlt size={20} />}{' '}
      </button>
    </li>
  );
};

export default Todo;

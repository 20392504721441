import SignIn from '../../auth/SignIn';
import SignUp from '../../auth/SignUp';
import AuthDetails from '../../AuthDetails';
import { auth } from '../../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useState } from 'react';

function Account() {
  const [user] = useAuthState(auth);
  const [isSignIn, setIsSignIn] = useState(true);

  const toggleMode = () => {
    setIsSignIn(!isSignIn);
  };

  return (
    <div className="App">
      {user ? (
        <AuthDetails />
      ) : (
        <>
          {isSignIn ? (
            <SignIn toggleMode={toggleMode} />
          ) : (
            <SignUp toggleMode={toggleMode} />
          )}
          <p onClick={toggleMode}>
            {isSignIn
              ? "I don't have an account..."
              : 'I already have an account'}
          </p>
        </>
      )}
    </div>
  );
}

export default Account;

import React, { useState } from 'react';

function ChangeBack() {
  const [bgColor, setBgColor] = useState('#ffffff');

  const handleClick = () => {
    const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
    setBgColor(randomColor);
  };

  return (
    <>
      <div style={{ backgroundColor: bgColor, height: '100vh' }}>
        <button onClick={handleClick}>Change Background Color</button>
      </div>
    </>
  );
}

export default ChangeBack;

import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../home/Home.css';
import './Contact.css';
import StarRating from './StarRating';

function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: name,
      from_email: email,
      rating: rating,
      message: comment,
    };

    emailjs
      .send(
        'Santiago_Giraldo',
        'sgiraldo_contact',
        templateParams,
        'e00prR-2--IaMURMI'
      )
      .then(
        (response) => {
          console.log(
            'Email sent successfully',
            response.status,
            response.text
          );
          alert('Email sent successfully');
        },
        (err) => {
          console.log('Email sending failed', err);
          alert('Email sending failed');
        }
      );

    setName('');
    setEmail('');
    setRating(0);
    setComment('');
  };
  return (
    <div>
      <>
        <h1>Contact Me</h1>
        <h2>Give Me Feedback</h2>
        <form onSubmit={handleSubmit} className="form">
          <div>
            <label>Name*</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="input"
            />
          </div>
          <div>
            <label>Email*</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="input"
            />
          </div>
          <div>
            <label>Rating</label>
            <StarRating
              rating={rating}
              onRatingChange={setRating}
              required
              className="input"
            />
          </div>
          <div>
            <label>Comment*</label>
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              required
              className="textarea"
            ></textarea>
          </div>
          <button type="submit" className="button">
            Submit
          </button>
        </form>
      </>
    </div>
  );
}

export default Contact;

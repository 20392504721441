import React from 'react';
import './Resume.css';
import '../home/Home.css';
import resume from '../../../assets/files/resume.pdf';

function Resume() {
  return (
    <>
      <div className="main-container">
        <h1>Resume</h1>
        <h3>NOTE: Site Under Construction</h3>
        <p>
          Here is where you can learn about what i have done in the past and
          what I am currently doing in my resume. At some point I will explain
          this information more in-depth for people to understand easier.
          <br />
        </p>
        <embed src={resume} width="75%" height="1200" type="application/pdf" />

        <div className="main-bottom">
          <h2 className="sec_header">My Info</h2>
          <ul className="links-list">
            {/* <!-- <li><a href="https://go.unl.edu/santiago_resume">My Resume</a></li>--> */}
            {/* <li>
              <a href="resume.pdf" target="_blank">
                My Resume
              </a>
            </li> */}
            {/* <!-- <embed src="resume/resume.pdf" type="application/pdf" width="100%" height="600px" style="background-color: #f5f1e7;"/> --> */}
            <li>
              <a
                href="https://www.linkedin.com/in/sgiraldo/"
                target="_blank"
                rel="noreferrer"
              >
                My LinkedIn
              </a>
            </li>
            <li>
              <a
                href="https://meritpages.com/sgiraldo"
                target="_blank"
                rel="noreferrer"
              >
                My Merit Page
              </a>
            </li>
            <li>
              <a
                href="https://scimath.unl.edu/stem-connect/"
                target="_blank"
                rel="noreferrer"
              >
                STEM CONNECT Scholar
              </a>
            </li>
            <li>
              <a
                href="https://nimbus.unl.edu/welcome"
                target="_blank"
                rel="noreferrer"
              >
                NIMBUS Lab Research
              </a>
            </li>
            {/* <!-- <li>
            <a
              href="../research/Viability_Analysis_of_Wireless_Communication_Hardware_for_Multi-rotor_Swarm_Integration.pdf"
              target="_blank"
              >My Research Poster 2021</a
            >
          </li> --> */}
          </ul>
          <h2>Another Title</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero ipsam
            sed qui aperiam eius libero eligendi porro officia, corporis
            facilis, assumenda a quas minus! Similique repudiandae minima et
            excepturi officia!
          </p>
        </div>
      </div>
    </>
  );
}

export default Resume;

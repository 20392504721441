import React, { useState } from 'react';
import './Counter.css';

function Counter() {
  const [count, setCount] = useState(0);
  const [beginTime, setBeginTime] = useState(null);
  const [currentTime, setCurrentTime] = useState(null);

  const increment = () => {
    if (count === 0) {
      setBeginTime(new Date());
    }
    setCount(count + 1);
    setCurrentTime(new Date());
  };

  const decrement = () => {
    if (count > 0) {
      setCount(count - 1);
      setCurrentTime(new Date());
    }
  };

  const reset = () => {
    setCount(0);
    setBeginTime(null);
    setCurrentTime(null);
  };

  return (
    <>
      <h1>Tally Counter</h1>
      <div id="counter">
        <div id="count-container">
          <button id="decrement" onClick={decrement}>
            -
          </button>
          <span id="count">{count}</span>
          <button id="increment" onClick={increment}>
            +
          </button>
        </div>
        <button id="reset" onClick={reset}>
          Reset
        </button>
        <div id="timestamps">
          <div id="begin-time">
            {beginTime ? `Begin Time: ${beginTime.toLocaleString()}` : ''}
          </div>
          <div id="current-time">
            {currentTime ? `Current Time: ${currentTime.toLocaleString()}` : ''}
          </div>
        </div>
      </div>
    </>
  );
}

export default Counter;

// StarRating.js
import React, { useState } from 'react';
import styled from '@emotion/styled';

const Star = styled.span`
  cursor: pointer;
  color: ${({ active }) => (active ? '#f1c40f' : '#333')};
  font-size: 2em;
`;

const StarRating = ({ rating, onRatingChange }) => {
  const [hoverRating, setHoverRating] = useState(0);

  const handleMouseEnter = (index) => {
    setHoverRating(index);
  };

  const handleMouseLeave = () => {
    setHoverRating(0);
  };

  const handleClick = (index) => {
    onRatingChange(index);
  };

  return (
    <div>
      {[1, 2, 3, 4, 5].map((index) => (
        <Star
          key={index}
          active={index <= (hoverRating || rating)}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleClick(index)}
        >
          ★
        </Star>
      ))}
    </div>
  );
};

export default StarRating;

import React, { useState, useEffect } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import Todo from './components/Todo';
import './TodoList.css';

import { db } from '../../../../../firebase';
import {
  query,
  collection,
  onSnapshot,
  updateDoc,
  doc,
  addDoc,
  deleteDoc,
} from 'firebase/firestore';

// const style = {
//   bg: `h-screen w-screen p-4 bg-gradient-to-r from-[#242424] to-[#323232]`,
//   container: `bg-slate-100 max-w-[500px] mx-auto p-4 rounded-md shadow-xl`,
//   heading: `text-3xl font-bold text-center text-gray-800 p-2`,
//   form: `flex justify-between`,
//   input: `border p-2 w-full text-xl`,
//   button: `border p-4 ml-2 bg-blue-500 text-slate-100`,
//   count: `text-center text-center`,
// };

function TodoList() {
  const [todos, setTodos] = useState([]);
  const [input, setInput] = useState('');

  // create todo
  const createTodo = async (e) => {
    e.preventDefault();
    if (input === '') {
      alert('Please enter a todo');
      return;
    }
    await addDoc(collection(db, 'todos'), {
      text: input,
      completed: false,
    });
    setInput('');
  };

  // read todo from firebase
  useEffect(() => {
    const q = query(collection(db, 'todos'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let todoArr = [];
      querySnapshot.forEach((doc) => {
        todoArr.push({ ...doc.data(), id: doc.id });
      });
      setTodos(todoArr);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  // update todofrom firebase
  const toggleComplete = async (todo) => {
    await updateDoc(doc(db, 'todos', todo.id), {
      completed: !todo.completed,
    });
  };

  // delete todo
  const deleteTodo = async (id) => {
    await deleteDoc(doc(db, 'todos', id));
  };

  return (
    <div
      // className={style.bg}
      className="bg"
      //   style={myStyles.bg}
    >
      <div
        //   className={style.container}
        className="container"
        // style={myStyles.container}
      >
        <h3
          // className={style.heading}
          className="heading"
          //   style={myStyles.heading}
        >
          Team Todo List
        </h3>
        <form
          onSubmit={createTodo}
          // className={style.form}
          className="form"
          //   style={myStyles.form}
        >
          <input
            value={input}
            onChange={(e) => setInput(e.target.value)}
            // className={style.input}
            className="input"
            // style={myStyles.input}
            type="text"
            placeholder="Add Item"
          />
          <button
            //   className={style.button}
            className="button"
            // style={myStyles.button}
          >
            <AiOutlinePlus size={30}></AiOutlinePlus>
          </button>
        </form>
        <ul>
          {todos.map((todo, index) => (
            <Todo
              key={index}
              todo={todo}
              toggleComplete={toggleComplete}
              deleteTodo={deleteTodo}
            />
          ))}
        </ul>
        {todos.length === 0 ? null : (
          <p
            //   className={style.count}
            className="count"
            // style={myStyles.count}
          >{`You have ${todos.length} todos`}</p>
        )}
      </div>
    </div>
  );
}

export default TodoList;

import React from 'react';
import './Feedback.css';

// function submitForm(event) {
// 	event.preventDefault();

// 	// Gets input email from field
// 	const emailInput = document.getElementById('email');
//     // Gets input feedback from field
//     const feedbackInput = document.getElementById('feedback');
//     //  Gets error message element
// 	const errorMessage = document.getElementById('error-message');

// 	// Makes temp variable for email value to validate
// 	const email = emailInput.value;
//     const rating = document.querySelector('input[name="rating"]:checked');
// 	const ratingValue = rating ? rating.value : '';

// 	// Get the feedback value
// 	const feedback = feedbackInput.value;

// 	// If the email is valid, submit the form
// 	if (validateEmail(email)) {
// 		// Send the form data to the server (replace with your own code)
// 		sendData(email, ratingValue, feedback);

// 		// Show a success message and reset the form
// 		alert('Form submitted!');
// 		emailInput.value = '';
// 		feedbackInput.value = '';
// 		errorMessage.textContent = '';
// 		document.querySelectorAll('input[name="rating"]').forEach(input => input.checked = false);
// 	}
// 	// If the email is not valid, display an error message
// 	else {
// 		errorMessage.textContent = 'Please enter a valid email address.';
// 	}
// }

// function validateEmail(email) {
// 	// A simple email validation regular expression
// 	const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
// 	return regex.test(email);
// }

// // function sendData(email, rating, feedback) {
// // // data to the server
// // 	console.log(`Email: ${email}\nRating: ${rating}\nFeedback: ${feedback}`);
// // }

function Feedback() {
  return (
    <>
      <div className="main-container">
        <div className="main-bottom">
          <div className="form-container">
            {/* <!-- <h1>Email Verification and Submission Form</h1> --> */}
            <form onsubmit="submitForm(event)">
              <h1>Your Feedback</h1>
              <label for="feedback">Rating:</label>
              <div className="rating">
                <input type="radio" id="star5" name="rating" value="5" />
                <label for="star5" title="5 stars">
                  &#9733;
                </label>
                <input type="radio" id="star4" name="rating" value="4" />
                <label for="star4" title="4 stars">
                  &#9733;
                </label>
                <input type="radio" id="star3" name="rating" value="3" />
                <label for="star3" title="3 stars">
                  &#9733;
                </label>
                <input type="radio" id="star2" name="rating" value="2" />
                <label for="star2" title="2 stars">
                  &#9733;
                </label>
                <input type="radio" id="star1" name="rating" value="1" />
                <label for="star1" title="1 star">
                  &#9733;
                </label>
              </div>
              <label for="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                required
              />
              <div className="feedback">
                <label for="feedback">Feedback:</label>
                <textarea
                  id="feedback"
                  name="feedback"
                  placeholder="Write your feedback here..."
                ></textarea>
              </div>
              <p id="error-message"></p>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Feedback;

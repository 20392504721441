import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/pages/home/Home';
import About from './components/pages/about/About';
import Contact from './components/pages/contact/Contact';
import Apps from './components/pages/apps/Apps';
import Resume from './components/pages/resume/Resume';
import Account from './components/pages/account/Account';
import ChangeBack from './components/pages/apps/allApps/changeback/ChangeBack';
import Feedback from './components/pages/apps/allApps/feedback/Feedback';
import NumberGuess from './components/pages/apps/allApps/numberGuess/NumberGuess';
import Counter from './components/pages/apps/allApps/counter/Counter';
import TodoList from './components/pages/apps/allApps/todoList/TodoList';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          {/* <Route path="/" exact /> */}
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/apps" element={<Apps />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/account" element={<Account />} />
          <Route path="/apps/changeback" element={<ChangeBack />} />
          <Route path="/apps/feedback" element={<Feedback />} />
          <Route path="/apps/numberGuess" element={<NumberGuess />} />
          <Route path="/apps/counter" element={<Counter />} />
          <Route path="/apps/todoList" element={<TodoList />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;

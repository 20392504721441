import React, { useEffect, useState } from 'react';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';

const AuthDetailsNav = () => {
  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthUser(user);
      } else {
        setAuthUser(null);
      }
    });
    return () => {
      listen();
    };
  }, []);

  return (
    <div>
      {authUser ? (
        <>
          <p style={{ fontSize: '2rem' }}>{`${authUser.email}`}</p>
        </>
      ) : (
        <p style={{ fontSize: '2rem' }}>
          <i className="fa-sharp fa-solid fa-person"></i>
        </p>
      )}
    </div>
  );
};

export default AuthDetailsNav;

import React, { useState } from 'react';
import './NumberGuess.css';

const NumberGuess = () => {
  const [randomNumber, setRandomNumber] = useState(
    Math.floor(Math.random() * 100) + 1
  );
  const [userGuess, setUserGuess] = useState('');
  const [remainingAttempts, setRemainingAttempts] = useState(10);
  const [message, setMessage] = useState('');

  const handleInputChange = (e) => {
    setUserGuess(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const guess = parseInt(userGuess);
    if (isNaN(guess) || guess < 1 || guess > 100) {
      setMessage('Invalid guess. Please enter a number between 1 and 100.');
      return;
    }

    setRemainingAttempts(remainingAttempts - 1);

    if (guess === randomNumber) {
      setMessage('Congratulations! You guessed the correct number.');
    } else if (remainingAttempts <= 1) {
      setMessage('Game over. You have no more attempts.');
    } else {
      setMessage(
        `${guess > randomNumber ? 'Lower' : 'Higher'} . You have ${
          remainingAttempts - 1
        } attempts left.`
      );
    }
  };

  const resetGame = () => {
    setRandomNumber(Math.floor(Math.random() * 100) + 1);
    setUserGuess('');
    setRemainingAttempts(10);
    setMessage('');
  };

  return (
    <div className="number-guess">
      <h2>Number Guessing Game</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="guess">Enter your guess:</label>
        <input
          type="number"
          id="guess"
          name="guess"
          value={userGuess}
          onChange={handleInputChange}
          min="1"
          max="100"
        />
        <button type="submit">Submit</button>
      </form>
      {message && <p>{message}</p>}
      {remainingAttempts <= 0 && <button onClick={resetGame}>Try Again</button>}
    </div>
  );
};

export default NumberGuess;

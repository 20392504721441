import React from 'react';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import './Apps.css';
import '../home/Home.css';

const appItems = [
  {
    text: 'Random Background',
    to: '/apps/changeback',
  },
  {
    text: 'Feedback',
    to: '/apps/feedback',
  },
  {
    text: 'Higher or Lower',
    to: '/apps/numberguess',
  },
  {
    text: 'Counter',
    to: '/apps/counter',
  },
  {
    text: 'Account',
    to: '/account',
  },
  {
    text: 'Team Todo List',
    to: '/apps/todolist',
  },
];

const myStyles = {
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '25px',
  },
  myItem: {
    display: 'inline-block',
    margin: '10px',
    padding: '10px 20px',
    backgroundColor: '#007bff',
    borderRadius: '1rem',
  },
  myLinks: {
    display: 'inline-block',
    color: '#fff',
    textDecoration: 'none',
    fontSize: '2rem',
    transition: 'all 0.5s ease-in-out',
  },
};

function Apps() {
  const mappedItems = appItems.map(({ to, text }) => {
    return (
      <Link to={to} style={myStyles.myItem}>
        <Button style={myStyles.myLinks}>{text}</Button>
      </Link>
    );
  });
  return (
    <>
      <div className="main-container">
        <h1>Pages</h1>
        {/* <h3>NOTE: Site Under Construction</h3> */}
        <p>
          Welcome to my website! Here, you will find a list of applications I
          have created. As a software developer, I am passionate about creating
          innovative and user-friendly applications that can solve real-world
          problems. My applications are designed with the end-user in mind, and
          I strive to make them as intuitive and efficient as possible. Whether
          you're looking for a productivity tool or a fun game, I hope you will
          find something that catches your interest among the applications
          listed on this page. Thank you for visiting, and please don't hesitate
          to contact me if you have any questions or feedback!
        </p>

        <div className="main-bottom">
          <h2>Choose a page!</h2>

          {/* NEW TESTING */}
          <Box display="flex" gap={2} style={myStyles.buttonGroup}>
            {mappedItems}
          </Box>
        </div>
      </div>
    </>
  );
}

export default Apps;

import React from 'react';
import './MyButton.css';

const MyButton = () => {
  return (
    <>
      <div className="button-box">
        <div className="button-text">Button Primary</div>
      </div>
    </>
  );
};

export default MyButton;
